import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./App.css";
import { Header, Keyboard, Puzzles } from "./components";
import { checkValidity } from "./util/checkValidity";
import { generateWordlist } from "./util/generateWordlist";
import { sortByValue } from "./util/sortByValue";
import HelpModal from "./components/HelpModal";
import FinishModal from "./components/FinishModal";
import Confetti from "react-dom-confetti";

const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Content = styled.div`
  /* box-shadow: 4px 4px 40px 4px rgba(0, 0, 0, 0.2); */
  /* background-color: #fff; */
  /* margin-bottom: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function Game() {
  // Core Game
  const [wordlist, setWordlist] = useState(generateWordlist());
  const [guesslist, setGuesslist] = useState<string[]>([]);
  const [working, setWorking] = useState("");
  const [initialCount, setInitialCount] = useState(50);

  // Modal States
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isFinishModalOpen,
    onOpen: onFinishModalOpen,
    onClose: onFinishModalClose,
  } = useDisclosure();

  // Clock States
  const [isActive, setIsActive] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);

  // Effects
  const [confettiActive, setConfettiActive] = useState(false);

  useEffect(() => {
    let interval: any = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  function addKey(key: string) {
    if (key === "-" || key === "Backspace")
      setWorking((tmp) => tmp.slice(0, tmp.length - 1));
    else if (
      (key === "+" || key === "Enter") &&
      working.length === 5 &&
      checkValidity(working)
    ) {
      // lets go.
      const newGuesslist = guesslist.concat([working]);
      setGuesslist(newGuesslist);
      setWorking("");
      setWordlist(sortByValue(wordlist, newGuesslist));
    } else if (working.length !== 5 && key.length === 1) {
      setWorking((tmp) => tmp + key.toLowerCase());
    }
  }

  useEffect(() => {
    setWordlist(sortByValue(wordlist, guesslist));
  }, []);

  useEffect(() => {
    // If working is over 5 characters, trim it down to 5 characters
    if (working.length > 5) {
      setWorking(working.slice(0, 5));
      console.log(
        "Characters were typed for checks to be performed. Trimming working to 5 characters."
      );
    }

    function keyEvent(ev: KeyboardEvent) {
      addKey(ev.key);
    }
    window.addEventListener("keydown", keyEvent);
    return () => window.removeEventListener("keydown", keyEvent);
  }, [working]);

  const remainingWordleCount = wordlist.length;
  const guessCount = guesslist.length;

  // Trigger finish modal when there are no remaining puzzles
  useEffect(() => {
    if (remainingWordleCount === 0) {
      setIsPaused(true);
      setConfettiActive(true);
      onFinishModalOpen();
    }
  }, [remainingWordleCount, onFinishModalOpen]);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 180,
    dragFriction: 0.12,
    duration: 18000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    <div className="App">
      <Confetti active={confettiActive} config={confettiConfig} />

      <HelpModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      />
      <FinishModal
        isOpen={isFinishModalOpen}
        onClose={onFinishModalClose}
        guessCount={guessCount}
        time={time}
      />
      <Container>
        <Content>
          <Header
            remaining={remainingWordleCount}
            guesses={guesslist.length}
            time={time}
          />

          <Puzzles
            wordlist={wordlist}
            working={working}
            guesslist={guesslist}
            initialCount={initialCount}
            setInitialCount={setInitialCount}
          />
          <Keyboard
            onKeyPress={(key) => addKey(key)}
            remaining={remainingWordleCount}
            guesses={guesslist.length}
          />
        </Content>
      </Container>
    </div>
  );
}

export default Game;

import React from "react";
import styled from "styled-components";
import Clock from "./Clock";
import { Link } from "react-router-dom";

const StickyContainer = styled.div`
  /* Sticky */
  position: sticky;
  top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;

  background: rgba(255, 255, 255, 0.64);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-family: "PT Serif", serif;
`;

const Subtitle = styled.span`
  font-weight: normal;
  /* Link Styles */
  color: #00bcd4;
  text-decoration: underline;
  cursor: pointer;
  font-family: "PT Serif", serif;
`;

const Stats = styled.div`
  font-size: 25px;
  font-family: "PT Serif", serif;
`;

const LeaderboardLink = styled(Link)`
  font-size: 20px;

  font-family: "PT Serif", serif;
  padding-right: 10px;
  /* Link Styles */
  color: #00bcd4;
  text-decoration: underline;
  cursor: pointer;
`;

function Header({
  remaining,
  guesses,
  time,
}: {
  remaining: number;
  guesses: number;
  time: number;
}) {
  return (
    <StickyContainer>
      <Container>
        <Title>
          Myriadle by{" "}
          <Subtitle>
            <a href="https://josephsemrai.com" target="_blank">
              joe
            </a>
          </Subtitle>
        </Title>

        <div style={{ display: "flex" }}>
          <LeaderboardLink to="/leaderboard">View Leaderboard</LeaderboardLink>

          <Stats>
            <Clock time={time} />
          </Stats>
        </div>
      </Container>
    </StickyContainer>
  );
}

export default Header;

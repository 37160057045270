import React, { useEffect, useState } from "react";
import { convertTimeIntoDisplayFormat } from "../util/time";
import { db } from "../util/firebase";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Divider,
  Image,
  Kbd,
  Box,
  Input,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { doc, setDoc, collection, Timestamp } from "firebase/firestore";
import { Link } from "react-router-dom";
import Confetti from "react-dom-confetti";
export default function FinishModal({
  isOpen,
  onClose,
  time,
  guessCount,
}: {
  isOpen: boolean;
  onClose: () => void;
  time: number;
  guessCount: number;
}) {
  const toast = useToast();

  const [name, setName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [successfullyAddedScore, setSuccessfullyAddedScore] = useState(false);

  const daysSinceFeb26 = Math.floor(
    (Date.now() - new Date("February 26, 2022").getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const shareString = `Daily Myriadle #${daysSinceFeb26}
Solved in: ${convertTimeIntoDisplayFormat(time)}
Guesses: ${guessCount}
🟩🟩🟩🟩🟩
🟩🟩🟩🟩🟩
🟩🟩🟩🟩🟩
🟩🟩🟩🟩🟩
🟩🟩🟩🟩🟩
+9975 more

https://myriadle.semrai.com/
Leaderboard: https://myriadle.semrai.com/leaderboard`;

  const handleAddScore = async () => {
    // Validate that first name is greater than 2 characters less than 15. Otherwise return and toast
    if (name.length < 2 || name.length > 15) {
      toast({
        title: "Invalid Name",
        position: "top",
        description: "Name must be between 2 and 15 characters",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);

    // Add score to leaderboard using Firebase
    try {
      await setDoc(doc(collection(db, "leaderboard")), {
        displayName: name,
        time,
        guessCount,
        dateCreated: Timestamp.now(),
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Error",
        position: "top",
        description: "Could not add score to leaderboard",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    // Set state to show that score was added successfully
    setSuccessfullyAddedScore(true);
    setIsUploading(false);

    // Toast that score was added successfully
    toast({
      title: "Score Added",
      position: "top",
      description: "Your score was added to the leaderboard",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="80%" />
      <ModalContent>
        <ModalHeader>Congrats!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          You solved the Myriadle in {convertTimeIntoDisplayFormat(time)} using{" "}
          {guessCount} guesses!
          {/* Add save your score functionality with Firebase */}
          <Divider marginTop="15px" marginBottom="15px" />
          {successfullyAddedScore ? (
            <>
              <Text>Your score was added to the leaderboard!</Text>
              <Link
                to="/leaderboard"
                style={{ textDecoration: "underline", color: "red" }}
              >
                View the leaderboard
              </Link>
            </>
          ) : (
            <FormControl>
              <FormLabel>Add your score to the leaderboard</FormLabel>
              <HStack>
                <Input
                  size="lg"
                  placeholder="Type your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Button
                  colorScheme="red"
                  onClick={handleAddScore}
                  disabled={isUploading}
                >
                  Add
                </Button>
              </HStack>
              {isUploading && <Spinner />}
              <FormHelperText>
                Your name, guess count, and play time will appear publically if
                you are in the top 10.
              </FormHelperText>
            </FormControl>
          )}
          <Divider marginTop="15px" marginBottom="15px" />
          {/* Giant share button that copies the shareString to keyboard */}
          <Button
            colorScheme="red"
            variant="outline"
            size="lg"
            onClick={() => {
              toast({
                position: "top",
                title: "Copied Myriadle results to clipboard.",
                description: "Paste the results anywhere you want.",
                status: "success",
                duration: 9000,
                isClosable: true,
              });

              navigator.clipboard.writeText(shareString);
            }}
          >
            Share your score
          </Button>
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme="red" onClick={onClose}>
            Let's Play!
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React from "react";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Game from "./Game";
import ViewLeaderboardResult from "./ViewLeaderboardResult";
import Leaderboard from "./Leaderboard";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Game />} />

        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route
          path="/leaderboard/view/:id"
          element={<ViewLeaderboardResult />}
        />
      </Routes>
    </BrowserRouter>
  );
}

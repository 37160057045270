// Convert time in miliseconds to human readable format HH:MM:SS
export const convertTimeIntoDisplayFormat = (time: number): string => {
  const [MS_IN_SEC, SEC_IN_DAY, SEC_IN_HOUR, SEC_IN_MIN] = [
    1000, 86400, 3600, 60,
  ];
  let seconds = Math.round(Math.abs(time) / MS_IN_SEC);
  seconds = Math.floor(seconds % SEC_IN_DAY);
  const hours = Math.floor(seconds / SEC_IN_HOUR);
  seconds = Math.floor(seconds % SEC_IN_HOUR);
  const minutes = Math.floor(seconds / SEC_IN_MIN);
  seconds = Math.floor(seconds % SEC_IN_MIN);
  const [hh, mm, ss] = [hours, minutes, seconds].map((item) =>
    item < 10 ? "0" + item : item.toString()
  );
  return hh + ":" + mm + ":" + ss;
};

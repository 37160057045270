import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { db } from "./util/firebase";
import { convertTimeIntoDisplayFormat } from "./util/time";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

// Helper function to convert Firebase date to human readable MM/DD/YYYY format
function formatDate(date: { seconds: number; nanoseconds: number }) {
  const dateObj = new Date(date.seconds * 1000);

  return `${
    dateObj.getMonth() + 1
  }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
}

const LeaderboardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LeaderboardContent = styled.div`
  font-family: "Roboto Mono", monospace;
  width: 600px;

  background: #ffffff;
  box-shadow: 0 5px 10px -5px rgba(black, 0.2);
`;

const Title = styled.div`
  padding: 20px;
  background: #121212;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const List = styled.table`
  width: 100%;
`;

const Person = styled.tr`
  border-top: 15px solid transparent;

  align-items: center;
`;
const Name = styled.td`
  margin-left: 10px;
`;

const Guesses = styled.td`
  margin-left: auto;
  font-weight: 700;
`;

const Time = styled.td`
  font-weight: 700;
  width: 100px;
`;

const PlayedAt = styled.td`
  width: 100px;
`;

const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: #eeeeee;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding: 0px 20px 20px 20px;
  height: 70vh;
  overflow: auto;
`;

const TableHeading = styled.thead`
  font-weight: 700;
  border-top: 15px solid transparent;

  text-transform: uppercase;
`;

export default function Leaderboard() {
  const leaderboardRef = collection(db, "leaderboard");
  const topScoresQuery = query(leaderboardRef, orderBy("time"), limit(50));

  const [value, loading, error] = useCollectionData(topScoresQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return (
    <PageContainer>
      <LeaderboardContainer>
        <LeaderboardContent>
          <Title>
            <div style={{ marginBottom: "10px" }}>
              <Link to="/" style={{ textDecoration: "underline" }}>
                {"<-"} Go Back to Myriadle{" "}
              </Link>
            </div>

            <p>Leaderboard (Top 50 only)</p>
          </Title>
          {error && <strong>Error: {JSON.stringify(error)}</strong>}
          {loading && <span>Collection: Loading...</span>}
          <TableWrapper>
            <List>
              <colgroup>
                <col span={1} style={{ width: "15%" }} />
                <col span={1} style={{ width: "30%" }} />
                <col span={1} style={{ width: "10%" }} />
                <col span={1} style={{ width: "20%" }} />
              </colgroup>

              <TableHeading>
                <td>#</td>

                <td>Date Played</td>

                <td>Name</td>
                <td>Guesses</td>
                <td>Time</td>
              </TableHeading>
              {value?.map((person, index) => (
                <Person
                  key={index}
                  style={{
                    fontSize: index === 0 ? "1.3rem" : "1rem",
                    color: index === 0 ? "green" : "black",
                    fontWeight: index === 0 ? "700" : "400",
                  }}
                >
                  <td>#{index + 1}</td>
                  <PlayedAt>{formatDate(person.dateCreated)}</PlayedAt>
                  <Name>{person.displayName}</Name>
                  <Guesses>{person.guessCount}</Guesses>
                  <Time>{convertTimeIntoDisplayFormat(person.time)}</Time>
                </Person>
              ))}
            </List>
          </TableWrapper>
        </LeaderboardContent>
      </LeaderboardContainer>
    </PageContainer>
  );
}

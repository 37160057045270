import { Button } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import Puzzle from "./Puzzle";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;

const More = styled.div`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  font-family: "PT Serif", serif;
`;

const Directions = styled.div`
  font-size: 15px;

  text-align: center;
  font-family: "PT Serif", serif;
`;

const GradientButton = styled.button`
  background-color: #dd5e89;
  margin-top: 10px;
  padding: 15px;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  border-radius: 3;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  border: none;
`;

const EndingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function Puzzles({
  wordlist,
  working,
  guesslist,
  initialCount,
  setInitialCount,
}: {
  wordlist: string[];
  working: string;
  guesslist: string[];
  initialCount: number;
  setInitialCount: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <>
      <Container>
        {wordlist
          .slice(0, Math.min(wordlist.length, initialCount))
          .map((word) => (
            <Puzzle
              key={word}
              wordle={word}
              working={working}
              guesslist={guesslist}
            />
          ))}
      </Container>
      <EndingContainer>
        <More>+{Math.max(0, wordlist.length - initialCount)} more</More>
        <Directions>
          Solve a puzzle or press the button to display more Worldes.
        </Directions>
        <Button
          colorScheme="red"
          mt={6}
          onClick={() => setInitialCount((initialCount) => initialCount + 100)}
        >
          Display 100 More Wordles
        </Button>
      </EndingContainer>
    </>
  );
}

export default Puzzles;

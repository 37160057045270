import React from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Divider,
  Image,
  Kbd,
  Box,
} from "@chakra-ui/react";
import { collection, query, orderBy, limit } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../util/firebase";
import { convertTimeIntoDisplayFormat } from "../util/time";
export default function HelpModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;

  onClose: () => void;
}) {
  const leaderboardRef = collection(db, "leaderboard");
  const topScoresQuery = query(leaderboardRef, orderBy("time"), limit(1));

  const [value, loading, error] = useCollectionData(topScoresQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How to Play</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={2}>
            <Text>Guess the Myriadle in 10,000 tries.</Text>
            <Text>
              Each guess must be a valid five-letter word. Type and hit the{" "}
              <Kbd>enter</Kbd> or <Kbd>GO</Kbd> button to submit.
            </Text>

            <Text>
              <Box as="span" fontWeight="bold" color="red.700">
                Current World Record:{" "}
              </Box>
              {loading && "Loading..."}
              {error && "Error..."}
              {value &&
                `${value[0].displayName} in ${convertTimeIntoDisplayFormat(
                  value[0].time
                )}`}
            </Text>
            <Text>
              Try to get the fastest time!{" "}
              <Link
                to="/leaderboard"
                style={{
                  fontWeight: "bold",
                  color: "red",
                  textDecoration: "underline",
                }}
              >
                View Leaderboard
              </Link>
            </Text>

            <Divider borderColor="blackAlpha.500" />
            <Text fontWeight="bold">What's different from Wordle?</Text>
            <Text>
              Each one of the 10,000 Wordle puzzles has its own solution.
              Guesses will apply to all puzzles.
            </Text>
            <Text>Guesses can "stack" to form the correct solution.</Text>
            <Divider borderColor="blackAlpha.500" />
            <Text fontWeight="bold">Stacking Example</Text>
            <Image
              width="200px"
              src="https://user-images.githubusercontent.com/29003194/155862156-7d414d93-7267-4f43-9793-83f92eb80412.png"
            />
            <Text>
              The words "CRIPE" and "SLIME" have stacked in this puzzle to get
              the correct solution, "CRIME".
            </Text>
            <Divider borderColor="blackAlpha.500" />
            <Text fontWeight="bold">New Rows After Guess Example</Text>
            <Image
              width="200px"
              src="https://user-images.githubusercontent.com/29003194/155862465-a3dd2728-13e2-4217-8880-083cec47831a.gif"
            />
            <Text>
              New rows appear after each guess if the puzzle has not yet been
              solved.
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" onClick={onClose}>
            Let's Play!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 100vw;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  /* Glass */
  background: rgba(255, 255, 255, 0.53);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.9px);
  -webkit-backdrop-filter: blur(11.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  border-top: 1px solid hsla(0, 0%, 100%, 0.15);
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 10px;
  /* max-width: 600px; */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Key = styled.div<{ letter: string }>`
  width: ${({ letter }) => (letter === "+" ? "55px" : "40px")};

  height: 50px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 3px;
  cursor: pointer;
  background-color: #d3d6da;
  user-select: none;
  :hover {
    background-color: #eee;
  }
`;

const Stats = styled.div`
  font-size: 25px;
  font-family: "PT Serif", serif;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const rows = ["QWERTYUIOP", "ASDFGHJKL", "+ZXCVBNM-"];

function Keyboard({
  onKeyPress,
  remaining,
  guesses,
}: {
  onKeyPress: (key: string) => void;
  remaining: number;
  guesses: number;
}) {
  return (
    <Container>
      <Content>
        <Stats>
          Guesses: {guesses} | {remaining} Wordles Left
        </Stats>
        {rows.map((row) => (
          <Row key={row}>
            {row.split("").map((letter) => (
              <Key
                key={letter}
                letter={letter}
                onClick={() => onKeyPress(letter)}
              >
                {letter === "+" ? "GO" : letter === "-" ? "⌫" : letter}
              </Key>
            ))}
          </Row>
        ))}
      </Content>
    </Container>
  );
}

export default Keyboard;

import React from "react";
import styled from "styled-components";
import { checkValidity } from "../util/checkValidity";
import { isYellow } from "../util/isYellow";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: #ffbebc;

  margin: 2px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Letter = styled.div<{ green?: boolean; yellow?: boolean; red?: boolean }>`
  display: flex;
  margin: 2px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #f7f8f9;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  ${({ yellow }) =>
    yellow &&
    `
    background-color: #EEDE23;  
  `}
  ${({ green }) =>
    green &&
    `
    background-color: #22EE33;  
  `}
  ${({ red }) =>
    red &&
    `
    background-color: #DE5956;
  `}
`;

function Puzzle({
  wordle,
  working,
  guesslist,
}: {
  wordle: string;
  working: string;
  guesslist: string[];
}) {
  return (
    <Container>
      {guesslist.map((guess, gnum) => (
        <Row key={guess + gnum}>
          {guess.split("").map((letter, i) => (
            <Letter
              key={`${letter}${i},${gnum}`}
              green={letter === wordle[i]}
              yellow={isYellow(wordle, guess, i)}
            >
              {letter.toUpperCase()}
            </Letter>
          ))}
        </Row>
      ))}
      <Row>
        {working.split("")?.map((letter, i) => (
          <Letter
            red={working.length === 5 && !checkValidity(working)}
            key={`${letter}${i}W`}
          >
            {letter.toUpperCase()}
          </Letter>
        ))}
        {working.length !== 5 &&
          working.length < 6 &&
          Array.from(Array(5 - working.length)).map((e, i) => (
            <Letter key={`empty-${i}`}> </Letter>
          ))}
      </Row>
    </Container>
  );
}

export default Puzzle;

import React, { useState } from "react";
import styled from "styled-components";
import { convertTimeIntoDisplayFormat } from "../util/time";

// function ControlButtons(props) {
//   const StartButton = (
//     <div className="btn btn-one btn-start"
//          onClick={props.handleStart}>
//       Start
//     </div>
//   );
//   const ActiveButtons = (
//     <div className="btn-grp">
//       <div className="btn btn-two"
//            onClick={props.handleReset}>
//         Reset
//       </div>
//       <div className="btn btn-one"
//            onClick={props.handlePauseResume}>
//         {props.isPaused ? "Resume" : "Pause"}
//       </div>
//     </div>
//   );

const TimerWrapper = styled.span`
  width: 100px;

  font-family: "PT Serif", serif;
  font-size: 25px;
  margin-right: 40px;
  color: green;
`;

function Clock({ time }: { time: number }) {
  return <TimerWrapper>{convertTimeIntoDisplayFormat(time)}</TimerWrapper>;
}

export default Clock;
